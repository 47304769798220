<template>
    <div v-if="data">
        <div class="row">
            <div class="col-12 col-xl-3">
                <dashboard-performance />
            </div>
            <div class="col-12 col-xl-9">
                <dashboard-totals/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <dashboard-summary-by-type />
            </div>
            <div class="col-12 mt-6">
                <dashboard-recent-applications />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardPerformance from '../components/DashboardPerformance';
import DashboardRecentApplications from '../components/recent-applications/DashboardRecentApplications';
import DashboardTotals from '../components/DashboardTotals';
import DashboardSummaryByType from '../components/DashboardSummaryByType';

export default {
    name: 'DashboardStaff',
    components: {
        DashboardRecentApplications,
        DashboardPerformance,
        DashboardTotals,
        DashboardSummaryByType,
    },
    computed: {
        ...mapGetters({
            data: 'dashboard/data',
        })
    },
    methods: {
        ...mapActions({
            fetchData: 'dashboard/getDashboards'
        })
    },
    async created() {
        const types = 'total_applications,total_applications_by_type,applications_avg_resolution_goal,applications_performance';
        await this.fetchData(types);
    }
}
</script>
